import React, { useState, useEffect } from 'react';

const CompInventario = () => {
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(true); // Para manejar el estado de carga
  const [error, setError] = useState(null); // Para manejar errores

  useEffect(() => {
    // Función para obtener los productos desde la API
    const fetchInventory = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/productos/admin`);
        if (!response.ok) {
          throw new Error('Error al obtener los productos');
        }
        const data = await response.json();
        setInventory(data.productos); // Actualiza el estado con los productos obtenidos
        
        setLoading(false); // Desactiva el estado de carga
      } catch (error) {
        setError(error.message); // Maneja el error
        setLoading(false); // Desactiva el estado de carga incluso si hay un error
      }
    };

    fetchInventory(); // Llama a la función al montar el componente
  }, []); // [] asegura que el efecto se ejecute solo una vez al montar el componente

  console.log(inventory);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-3xl">
        <h1 className="text-2xl font-bold mb-6 text-center">Gestión de Inventario</h1>

        <div>
          <h2 className="text-xl font-semibold mb-4">Inventario Actual</h2>

          {/* Muestra un mensaje de carga mientras se obtiene la información */}
          {loading && <p className="text-center text-gray-500">Cargando inventario...</p>}

          {/* Muestra un mensaje de error si ocurrió un problema */}
          {error && <p className="text-center text-red-500">Error: {error}</p>}

          {/* Lista de productos obtenidos */}
          {!loading && !error && inventory.length > 0 ? (
            <ul className="space-y-4">
              {inventory.map((product) => (
                <li key={product.id} className="border p-4 rounded-lg shadow-md">
                  <h3 className="text-lg font-bold">{product.nombre_producto}</h3>
                  <p>Stock total: {product.stock_total}</p>
                  <p>Stock en palets: {product.stock_palets}</p>
                  <p>Stock en unidades: {product.stock_unidades}</p>
                  <p>Stock en kilos: {product.stock_kilos}</p>
                </li>
              ))}
            </ul>
          ) : (
            !loading && <p className="text-center text-gray-500">No hay productos en el inventario.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompInventario;
