import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';

const CompCrearDescuento = () => {
  const [tipo, setTipo] = useState('producto');
  const [idRelacionado, setIdRelacionado] = useState('');
  const [descuentoPorcentaje, setDescuentoPorcentaje] = useState('');
  const [descuentoFijo, setDescuentoFijo] = useState('');
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');
  const [activo, setActivo] = useState(true);
  const [productos, setProductos] = useState([]);
  const [subrubros, setSubrubros] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDatos = async () => {
      try {
        const productosResponse = await axios.get(`${process.env.REACT_APP_API_URL}/productos/admin`);
        setProductos(productosResponse.data.productos);

        const subrubrosResponse = await axios.get(`${process.env.REACT_APP_API_URL}/rubros/subrubros`);
        setSubrubros(subrubrosResponse.data);
      } catch (error) {
        console.error('Error al obtener datos:', error);
        setError('Error al obtener datos');
      } finally {
        setLoading(false);
      }
    };

    fetchDatos();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/descuentos`, {
        tipo,
        id_relacionado: idRelacionado,
        descuento_porcentaje: descuentoPorcentaje || null,
        descuento_fijo: descuentoFijo || null,
        fecha_inicio: fechaInicio,
        fecha_fin: fechaFin,
        activo,
      });

      alert('Descuento creado exitosamente');
    } catch (error) {
      console.error('Error:', error);
      alert('Error al crear el descuento');
    }
  };

  if (loading) return <p className="text-center">Cargando...</p>;
  if (error) return <p className="text-center text-red-500">{error}</p>;

  return (
    <motion.div
      className="container mx-auto p-6 bg-white rounded-lg shadow-lg"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className="text-2xl font-bold mb-6 text-center">Crear Descuento</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="tipo" className="block text-sm font-medium text-gray-700">Tipo de Descuento:</label>
          <select
            id="tipo"
            value={tipo}
            onChange={(e) => setTipo(e.target.value)}
            className="mt-2 block w-full border border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="producto">Producto</option>
            <option value="subrubro">Subrubro</option>
          </select>
        </div>
        {tipo === 'producto' && (
          <div>
            <label htmlFor="idRelacionado" className="block text-sm font-medium text-gray-700">Selecciona un Producto:</label>
            <select
              id="idRelacionado"
              value={idRelacionado}
              onChange={(e) => setIdRelacionado(e.target.value)}
              className="mt-2 block w-full border border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value="">Selecciona un producto</option>
              {productos.map(producto => (
                <option key={producto.id} value={producto.id}>{producto.nombre_producto}</option>
              ))}
            </select>
          </div>
        )}
        {tipo === 'subrubro' && (
          <div>
            <label htmlFor="idRelacionado" className="block text-sm font-medium text-gray-700">Selecciona un Subrubro:</label>
            <select
              id="idRelacionado"
              value={idRelacionado}
              onChange={(e) => setIdRelacionado(e.target.value)}
              className="mt-2 block w-full border border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value="">Selecciona un subrubro</option>
              {subrubros.map(subrubro => (
                <option key={subrubro.id} value={subrubro.id}>{subrubro.subrubro}</option>
              ))}
            </select>
          </div>
        )}
        <div>
          <label htmlFor="descuentoPorcentaje" className="block text-sm font-medium text-gray-700">Descuento Porcentaje:</label>
          <input
            id="descuentoPorcentaje"
            type="number"
            value={descuentoPorcentaje}
            onChange={(e) => setDescuentoPorcentaje(e.target.value)}
            className="mt-2 block w-full border border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="descuentoFijo" className="block text-sm font-medium text-gray-700">Descuento Fijo:</label>
          <input
            id="descuentoFijo"
            type="number"
            value={descuentoFijo}
            onChange={(e) => setDescuentoFijo(e.target.value)}
            className="mt-2 block w-full border border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="fechaInicio" className="block text-sm font-medium text-gray-700">Fecha Inicio:</label>
          <input
            id="fechaInicio"
            type="date"
            value={fechaInicio}
            onChange={(e) => setFechaInicio(e.target.value)}
            className="mt-2 block w-full border border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="fechaFin" className="block text-sm font-medium text-gray-700">Fecha Fin:</label>
          <input
            id="fechaFin"
            type="date"
            value={fechaFin}
            onChange={(e) => setFechaFin(e.target.value)}
            className="mt-2 block w-full border border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
        <div className="flex items-center">
          <input
            id="activo"
            type="checkbox"
            checked={activo}
            onChange={(e) => setActivo(e.target.checked)}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label htmlFor="activo" className="ml-2 block text-sm font-medium text-gray-700">Activo</label>
        </div>
        <button
          type="submit"
          className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Crear Descuento
        </button>
      </form>
    </motion.div>
  );
};

export default CompCrearDescuento;
