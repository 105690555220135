import React from "react";
import { Link } from "react-router-dom";

const CompShowInicio = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <h1 className="text-4xl font-bold mb-8">Bienvenido al Sistema </h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-4xl">
                <Link to="/caja" className="bg-white shadow-lg rounded-lg p-6 text-center hover:bg-gray-200 transition">
                    <h2 className="text-2xl font-semibold mb-2">Caja</h2>
                    <p className="text-gray-600">Accede al sistema de caja</p>
                </Link>
                <Link to="/vender" className="bg-white shadow-lg rounded-lg p-6 text-center hover:bg-gray-200 transition">
                    <h2 className="text-2xl font-semibold mb-2">Venta</h2>
                    <p className="text-gray-600">Accede al sistema de ventas</p>
                </Link>
                <Link to="/vender" className="bg-white shadow-lg rounded-lg p-6 text-center hover:bg-gray-200 transition">
                    <h2 className="text-2xl font-semibold mb-2">Comprar</h2>
                    <p className="text-gray-600">Accede al sistema de Compra</p>
                </Link>
                <Link to="/inventario" className="bg-white shadow-lg rounded-lg p-6 text-center hover:bg-gray-200 transition">
                    <h2 className="text-2xl font-semibold mb-2">Inventario</h2>
                    <p className="text-gray-600">Gestiona el inventario</p>
                </Link>
                <Link to="/crearDescuento" className="bg-white shadow-lg rounded-lg p-6 text-center hover:bg-gray-200 transition">
                    <h2 className="text-2xl font-semibold mb-2">Crear descuentos</h2>
                </Link>
            </div>
        </div>
    )
}

export default CompShowInicio
