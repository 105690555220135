import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaPlus } from 'react-icons/fa';
import axios from 'axios';
import CompCreateRubro from './createRubro';
import CompCreateProv from './createProv';

function CompCompra() {
  const [rubros, setRubros] = useState([]); // Almacena los rubros
  const [proveedores, setProveedores] = useState([]); // Almacena los rubros
  const [subrubros, setSubrubros] = useState([]); // Almacena los subrubros según el rubro seleccionado
  const [showCreateRubroModal, setShowCreateRubroModal] = useState(false); // Controla el modal
  const [showCreateProvModal, setShowCreateProvModal] = useState(false); // Controla el modal

  const [formData, setFormData] = useState({
    nombre_producto: '',
    tipo_producto: 'unidad',
    unidades_por_bulto: '',
    bultos_por_palet: '',
    precio: '',
    precio_compra: '',
    precio_mayorista_a: '',
    precio_mayorista_b: '',
    precio_mayorista_c: '',
    codigo_referencia: '',
    fecha_vencimiento: '',
    stock_palets: '',
    stock_bultos: '',
    stock_unidades: '',
    stock_kilos: '',
    rubro_id: '', // Almacenar el ID del rubro
    subrubro_id: '', // Almacenar el ID del subrubro
    fecha_vigencia: '',
    proveedor_id: '', // Almacenar el ID del proveedor
  });

  useEffect(() => {
    const fetchRubros = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/rubros`);
        setRubros(response.data);
      } catch (error) {
        console.error('Error al obtener los rubros:', error);
      }
    };

    fetchRubros();
  }, [showCreateRubroModal]);

  useEffect(() => {
    const fetchProvs = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/proveedores`);
        console.log(response);

        setProveedores(response.data.data);
      } catch (error) {
        console.error('Error al obtener los rubros:', error);
      }
    };

    fetchProvs();
  }, [showCreateProvModal]);



  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    };

    // Solo calcular el costo unitario si los campos relevantes están presentes
    const {
      precio_compra,
      stock_palets,
      stock_kilos,
      bultos_por_palet,
      unidades_por_bulto,
      stock_bultos,
      stock_unidades,
    } = updatedFormData;

    if (precio_compra && (stock_palets || stock_kilos || stock_bultos || stock_unidades)) {
      const costoUnitario = calculateCostoUnitario(
        parseFloat(precio_compra),
        parseFloat(stock_kilos),
        parseFloat(stock_palets),
        parseFloat(bultos_por_palet),
        parseFloat(unidades_por_bulto),
        parseFloat(stock_bultos),
        parseFloat(stock_unidades)
      );
      updatedFormData.costo_unitario = costoUnitario;
    }

    setFormData(updatedFormData);
  };

  const handleRubroChange = (e) => {
    const rubroId = e.target.value;
    const selectedRubro = rubros.find((rubro) => rubro.id === parseInt(rubroId));

    setFormData({
      ...formData,
      rubro_id: rubroId,
      subrubro_id: '', // Reinicia el subrubro al cambiar el rubro
    });

    if (selectedRubro) {
      setSubrubros(selectedRubro.subrubros); // Actualiza los subrubros disponibles
    } else {
      setSubrubros([]); // Si no hay rubro seleccionado, vacía los subrubros
    }
  };

  // Función para calcular el costo unitario
  const calculateCostoUnitario = (precio_compra, stock_kilos, stock_palets, bultos_por_palet, unidades_por_bulto, stock_bultos, stock_unidades) => {
    let unidades_totales = 0;

    // Si el stock está en kilos
    if (stock_kilos > 0) {
      return precio_compra / stock_kilos;
    }

    // Si el stock está en palets
    if (stock_palets > 0) {
      unidades_totales = stock_palets * bultos_por_palet * unidades_por_bulto;
      return precio_compra / unidades_totales;
    }

    // Si el stock está en bultos
    if (stock_bultos > 0) {
      unidades_totales = stock_bultos * unidades_por_bulto;
      return precio_compra / unidades_totales;
    }

    // Si el stock está en unidades
    if (stock_unidades > 0) {
      return precio_compra / stock_unidades;
    }

    return 0; // Si no hay unidades, bultos o palets
  };


  const handleProvChange = (e) => {
    const provId = e.target.value;
    const selectedProv = proveedores.find((prov) => prov.id === parseInt(provId));
    setFormData({
      ...formData,
      proveedor_id: provId,
    });

  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/compras`, {
        nombre_producto: formData.nombre_producto,
        tipo_producto: formData.tipo_producto,
        bultos_por_palet: formData.bultos_por_palet,
        unidades_por_bulto: formData.unidades_por_bulto,
        costo_unitario: formData.costo_unitario,
        precio: formData.precio,
        precio_compra: formData.precio_compra,
        precio_mayorista_a: formData.precio_mayorista_a || null,
        precio_mayorista_b: formData.precio_mayorista_b || null,
        precio_mayorista_c: formData.precio_mayorista_c || null,
        codigo_referencia: formData.codigo_referencia,
        fecha_vencimiento: formData.fecha_vencimiento,
        stock_palets: formData.stock_palets,
        stock_bultos: formData.stock_bultos,
        stock_unidades: formData.stock_unidades,
        stock_kilos: formData.stock_kilos,
        rubro_id: formData.rubro_id, // Asegúrate de enviar el ID correcto del rubro
        subrubro_id: formData.subrubro_id, // Asegúrate de enviar el ID correcto del subrubro
        fecha_vigencia: formData.fecha_vigencia,
        proveedor_id: formData.proveedor_id, // El proveedor debe enviarse correctamente
      });

      // Reiniciar el formulario después de enviar los datos
      setFormData({
        nombre_producto: '',
        tipo_producto: 'unidad',
        unidades_por_bulto: '',
        bultos_por_palet: '',
        precio: '',
        precio_compra: '',
        precio_mayorista_a: '',
        precio_mayorista_b: '',
        precio_mayorista_c: '',
        costo_unitario: '',
        codigo_referencia: '',
        fecha_vencimiento: '',
        stock_palets: '',
        stock_bultos: '',
        stock_unidades: '',
        stock_kilos: '',
        rubro_id: '', // Reiniciar rubro_id
        subrubro_id: '', // Reiniciar subrubro_id
        fecha_vigencia: '',
        proveedor_id: '', // Reiniciar proveedor_id
      });
    } catch (error) {
      console.error('Error al crear la compra:', error);
    }
  };


  return (<motion.form
    onSubmit={handleSubmit}
    className="max-w-2xl mx-auto p-8 bg-white rounded-xl shadow-lg space-y-6"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">Comprar Producto</h2>

    {/* Nombre del Producto */}
    <div className="grid grid-cols-1 gap-4">
      <label className="block">
        <span className="text-lg font-medium text-gray-700">Nombre del Producto:</span>
        <input
          type="text"
          name="nombre_producto"
          value={formData.nombre_producto}
          onChange={handleChange}
          className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
          required
        />
      </label>

      {/* Tipo de Producto */}
      <label className="block">
        <span className="text-lg font-medium text-gray-700">Tipo de Producto:</span>
        <select
          name="tipo_producto"
          value={formData.tipo_producto}
          onChange={handleChange}
          className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
        >
          <option value="unidad">Unidad</option>
          <option value="bulto">Bulto</option>
          <option value="kilo">Kilo</option>
          <option value="palet">Palet</option>
        </select>
      </label>
    </div>

    {/* Campos dependientes del tipo de producto */}
    {formData.tipo_producto === 'bulto' && (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <label className="block">
          <span className="text-lg font-medium text-gray-700">Unidades por Bulto:</span>
          <input
            type="number"
            name="unidades_por_bulto"
            value={formData.unidades_por_bulto}
            onChange={handleChange}
            className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
          />
        </label>
        <label className="block">
          <span className="text-lg font-medium text-gray-700">Cantidad en Bultos:</span>
          <input
            type="number"
            name="stock_bultos"
            value={formData.stock_bultos}
            onChange={handleChange}
            className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
          />
        </label>
      </div>
    )}

    {formData.tipo_producto === 'palet' && (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <label className="block">
          <span className="text-lg font-medium text-gray-700">Bultos por Palet:</span>
          <input
            type="number"
            name="bultos_por_palet"
            value={formData.bultos_por_palet}
            onChange={handleChange}
            className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
          />
        </label>
        <label className="block">
          <span className="text-lg font-medium text-gray-700">Unidades por Bulto:</span>
          <input
            type="number"
            name="unidades_por_bulto"
            value={formData.unidades_por_bulto}
            onChange={handleChange}
            className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
          />
        </label>
        <label className="block">
          <span className="text-lg font-medium text-gray-700">Cantidad en Palets:</span>
          <input
            type="number"
            name="stock_palets"
            value={formData.stock_palets}
            onChange={handleChange}
            className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
          />
        </label>
      </div>
    )}

    {/* Precios */}
    <div className="grid grid-cols-1 gap-4">
      <label className="block">
        <span className="text-lg font-medium text-gray-700">Precio Total de Compra:</span>
        <input
          type="number"
          name="precio_compra"
          value={formData.precio_compra}
          onChange={handleChange}
          className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
          required
        />
      </label>

      <label className="block">
        <span className="text-lg font-medium text-gray-700">Costo Unitario:</span>
        <input
          type="number"
          name="costo_unitario"
          value={formData.costo_unitario}
          onChange={handleChange}
          disabled
          className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500 bg-gray-100"
        />
      </label>

      <label className="block">
        <span className="text-lg font-medium text-gray-700">Precio Venta:</span>
        <input
          type="number"
          name="precio"
          value={formData.precio}
          onChange={handleChange}
          className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
          required
        />
      </label>
    </div>

    {/* Precios Mayoristas */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <label className="block">
        <span className="text-lg font-medium text-gray-700">Precio Mayorista A:</span>
        <input
          type="number"
          name="precio_mayorista_a"
          value={formData.precio_mayorista_a}
          onChange={handleChange}
          className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
        />
      </label>
      <label className="block">
        <span className="text-lg font-medium text-gray-700">Precio Mayorista B:</span>
        <input
          type="number"
          name="precio_mayorista_b"
          value={formData.precio_mayorista_b}
          onChange={handleChange}
          className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
        />
      </label>
      <label className="block">
        <span className="text-lg font-medium text-gray-700">Precio Mayorista C:</span>
        <input
          type="number"
          name="precio_mayorista_c"
          value={formData.precio_mayorista_c}
          onChange={handleChange}
          className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
        />
      </label>
    </div>

    {/* Código de Referencia */}
    <label className="block">
      <span className="text-lg font-medium text-gray-700">Código de Referencia:</span>
      <input
        type="text"
        name="codigo_referencia"
        value={formData.codigo_referencia}
        onChange={handleChange}
        className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
      />
    </label>

    {/* Fechas */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <label className="block">
        <span className="text-lg font-medium text-gray-700">Fecha de Vencimiento:</span>
        <input
          type="date"
          name="fecha_vencimiento"
          value={formData.fecha_vencimiento}
          onChange={handleChange}
          className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
        />
      </label>

      <label className="block">
        <span className="text-lg font-medium text-gray-700">Fecha de Vigencia:</span>
        <input
          type="date"
          name="fecha_vigencia"
          value={formData.fecha_vigencia}
          onChange={handleChange}
          className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
        />
      </label>
    </div>
    {/* Rubro */}
    <label className="block">
      <span className="text-lg font-medium text-gray-700">Rubro:</span>
      <select
        name="rubro_id"
        value={formData.rubro_id}
        onChange={handleRubroChange} // Usar el nuevo handle para cargar subrubros
        className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
      >
        <option value="">Selecciona un Rubro</option>
        {rubros.map((rubro) => (
          <option key={rubro.id} value={rubro.id}>
            {rubro.rubro}
          </option>
        ))}
      </select>
      <button
        type="button"
        onClick={() => setShowCreateRubroModal(true)}
        className="mt-3 inline-flex justify-center bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600"
      >
        Crear Rubro
      </button>
    </label>

    {/* Subrubro */}
    {formData.rubro_id && subrubros.length > 0 && (
      <label className="block mt-4">
        <span className="text-lg font-medium text-gray-700">Subrubro:</span>
        <select
          name="subrubro_id"
          value={formData.subrubro_id}
          onChange={handleChange}
          className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
        >
          <option value="">Selecciona un Subrubro</option>
          {subrubros.map((subrubro) => (
            <option key={subrubro.id} value={subrubro.id}>
              {subrubro.subrubro}
            </option>
          ))}
        </select>
      </label>
    )}


    {/* Proveedor */}
    <label className="block">
      <span className="text-lg font-medium text-gray-700">Proveedor:</span>
      <select
        name="proveedor_id"
        value={formData.proveedor_id}
        onChange={handleChange}
        className="mt-2 w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:ring focus:border-blue-500"
      >
        <option value="">Selecciona un Proveedor</option>
        {proveedores.map((prov) => (
          <option key={prov.id} value={prov.id}>
            {prov.nombre}
          </option>
        ))}
      </select>
      <button
        type="button"
        onClick={() => setShowCreateProvModal(true)}
        className="mt-3 inline-flex justify-center bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600"
      >
        Crear Proveedor
      </button>
    </label>

    {/* Botón Final */}
    <div className="flex justify-center mt-6">
      <button
        type="submit"
        className="w-full max-w-xs bg-blue-600 text-white py-3 px-4 rounded-xl shadow-md hover:bg-blue-700 flex items-center justify-center"
      >
        <FaPlus className="mr-2" /> Crear Producto
      </button>
    </div>

    {showCreateRubroModal && (
      <CompCreateRubro onClose={() => setShowCreateRubroModal(false)} />
    )}
    {showCreateProvModal && (
      <CompCreateProv onClose={() => setShowCreateProvModal(false)} />
    )}
  </motion.form>

  );
}

export default CompCompra;
