import React, { useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';

function CompCreateRubro({ onClose }) {
  const [rubroData, setRubroData] = useState({
    rubro: '',
    subrubros: [{ nombre: '' }],
  });

  const handleRubroChange = (e) => {
    const { name, value } = e.target;
    setRubroData({
      ...rubroData,
      [name]: value,
    });
  };

  const handleSubrubroChange = (index, value) => {
    const newSubrubros = [...rubroData.subrubros];
    newSubrubros[index].nombre = value;
    setRubroData({
      ...rubroData,
      subrubros: newSubrubros,
    });
  };

  const addSubrubro = () => {
    setRubroData({
      ...rubroData,
      subrubros: [...rubroData.subrubros, { nombre: '' }],
    });
  };

  const removeSubrubro = (index) => {
    const newSubrubros = rubroData.subrubros.filter((_, i) => i !== index);
    setRubroData({
      ...rubroData,
      subrubros: newSubrubros,
    });
  };

  const handleButtonClick = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/rubros`, rubroData);
      console.log('Rubro creado:', response.data);
      onClose(); // Cierra el modal después de crear el rubro
    } catch (error) {
      console.error('Error al crear el rubro:', error);
    }
  };

  return (
    <motion.div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
        <h2 className="text-2xl font-semibold mb-6">Crear Rubro</h2>
        <div>
          {/* Input para el nombre del Rubro */}
          <div className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">Nombre del Rubro:</label>
            <input
              type="text"
              name="rubro"
              value={rubroData.rubro}
              onChange={handleRubroChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
              required
            />
          </div>

          {/* Subrubros dinámicos */}
          {rubroData.subrubros.map((subrubro, index) => (
            <div key={index} className="mb-4 flex items-center">
              <input
                type="text"
                value={subrubro.nombre}
                onChange={(e) => handleSubrubroChange(index, e.target.value)}
                placeholder={`Subrubro ${index + 1}`}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
                required
              />
              {rubroData.subrubros.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeSubrubro(index)}
                  className="ml-2 text-red-500 hover:text-red-700"
                >
                  Eliminar
                </button>
              )}
            </div>
          ))}

          {/* Botón para añadir más subrubros */}
          <button
            type="button"
            onClick={addSubrubro}
            className="mb-4 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
          >
            Añadir Subrubro
          </button>

          {/* Botones de acción */}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-4 bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400"
            >
              Cancelar
            </button>
            <button
              type="button" // Cambio a type="button"
              onClick={handleButtonClick} // Maneja el clic para enviar los datos
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
            >
              Crear Rubro
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default CompCreateRubro;
