import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';

const CompCarrito = () => {
  const [cart, setCart] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [useWholesale, setUseWholesale] = useState(false); // Modo mayorista
  const [loading, setLoading] = useState(false); // Para manejar el estado de carga
  const [error, setError] = useState(null); // Para manejar posibles errores
  const [paymentMethod, setPaymentMethod] = useState('');  // Estado para el método de pago


  // Llamada a la API para obtener los productos
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/productos/admin`);
        setProductsData(response.data.productos);
        console.log(response.data);

      } catch (error) {
        console.error('Error al cargar productos:', error);
        setError('No se pudo cargar la lista de productos.');
      }
    };

    fetchProducts();
  }, []);

  // Función para calcular el precio basado en el modo (normal o mayorista)
  const getPrice = (product) => {
    console.log(product);

    if (useWholesale) {
      if (product.precios[0].precio_mayorista_c) return product.precios[0].precio_mayorista_c;
      if (product.precios[0].precio_mayorista_b) return product.precios[0].precio_mayorista_b;
      if (product.precios[0].precio_mayorista_a) return product.precios[0].precio_mayorista_a;
    }
    return product.precios[0].precio;
  };

  // Determina si el producto tiene descuento
  const isDiscounted = (product) => product.tieneDescuento;

  // Calcula el precio con descuento
  const getDiscountedPrice = (product) => {
    if (isDiscounted(product)) {
      return parseFloat(product.precioFinal.toFixed(2));
    }
    return getPrice(product);
  };

  const addToCart = (product) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((p) => p.id === product.id);
      if (existingProduct) {
        return prevCart.map((p) =>
          p.id === product.id ? { ...p, quantity: p.quantity + 1 } : p
        );
      } else {
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
  };

  const removeFromCart = (productId) => {
    setCart((prevCart) => prevCart.filter((product) => product.id !== productId));
  };

  const increaseQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart.map((product) =>
        product.id === productId
          ? { ...product, quantity: product.quantity + 1 }
          : product
      )
    );
  };

  const decreaseQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart.map((product) =>
        product.id === productId && product.quantity > 1
          ? { ...product, quantity: product.quantity - 1 }
          : product
      )
    );
  };

  const calculateTotal = () => {
    const total = cart.reduce((acc, product) => acc + getDiscountedPrice(product) * product.quantity, 0);
    return parseFloat(total.toFixed(2));
  };
  const handleCheckout = async () => {
    if (!paymentMethod) {
      alert('Por favor, selecciona un método de pago.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const payload = {
        productos: cart.map((item) => ({
          id: item.id,
          cantidad: item.quantity,
          precio: getDiscountedPrice(item),
          tipo_producto: item.tipo_producto,
          unidades_por_bulto: item.unidades_por_bulto,
          bultos_por_palet: item.bultos_por_palet,
          stock_palets: item.stock_palets,
          stock_bultos: item.stock_bultos,
          stock_unidades: item.stock_unidades,
          stock_kilos: item.stock_kilos,
        })),
        total: calculateTotal(),
        mayorista: useWholesale,
        detalles_pago: paymentMethod,  // Añadido el método de pago
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/ventas`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Error al realizar la venta');
      }

      const result = await response.json();
      console.log('Venta realizada:', result);

      setCart([]);
      alert('Venta realizada con éxito');
    } catch (error) {
      console.error('Error en el proceso de compra:', error);
      setError('Hubo un problema al realizar la compra.');
    } finally {
      setLoading(false);
    }
  };

  const isNearExpiry = (product) => {
    if (!product.fecha_vencimiento) return false;
    const hoy = new Date();
    const diasHastaVencimiento = Math.floor((new Date(product.fecha_vencimiento) - hoy) / (1000 * 60 * 60 * 24));
    return diasHastaVencimiento <= 7;  // Si faltan 7 días o menos
  };


  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">Productos Disponibles</h2>
        <div className="flex items-center">
          <label className="mr-2 text-gray-700">Modo Mayorista:</label>
          <input
            type="checkbox"
            checked={useWholesale}
            onChange={() => setUseWholesale(!useWholesale)}
            className="form-checkbox h-6 w-6 text-green-600"
          />
        </div>
      </div>

      {/* Lista de productos */}
      <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {Array.isArray(productsData) && productsData.length > 0 ? (
          productsData.map((product) => (
            <li key={product.id} className={`bg-white shadow-lg rounded-lg p-4 ${isDiscounted(product) ? 'border-2 border-red-500' : ''} ${isNearExpiry(product) ? 'bg-yellow-100' : ''}`}>
              <div className="text-center">
                <h3 className="text-lg font-bold text-gray-800">{product.nombre_producto}</h3>
                <p className={`text-xl font-semibold mt-2 ${isDiscounted(product) ? 'text-red-600 line-through' : 'text-green-700'}`}>
                  ${getPrice(product)}
                </p>
                {isDiscounted(product) && (
                  <p className="text-xl font-semibold text-green-700 mt-2">
                    ${getDiscountedPrice(product)}
                  </p>
                )}
                {isNearExpiry(product) && (
                  <p className="text-sm font-bold text-yellow-700 mt-1">
                    ¡Producto cerca de vencimiento!
                  </p>
                )}
              </div>
              <button
                onClick={() => addToCart(product)}
                className="mt-4 w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
              >
                Agregar al carrito
              </button>
            </li>
          ))
        ) : (
          <p>No hay productos disponibles</p>
        )}
      </ul>

      {/* Carrito de compras */}
      <h2 className="text-2xl font-bold text-gray-800 mt-10">Carrito de Compras</h2>
      <AnimatePresence>
        {cart.length > 0 ? (
          <motion.ul className="bg-white shadow-md rounded-lg p-4 mt-4">
            {cart.map((product) => (
              <motion.li
                key={product.id}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 50 }}
                transition={{ duration: 0.3 }}
                className="flex justify-between items-center py-4 border-b border-gray-200"
              >
                <div className="text-left">
                  <h3 className="text-lg font-bold text-gray-800">
                    {product.nombre_producto}
                  </h3>
                  <p className="text-gray-700">
                    ${getDiscountedPrice(product)} x {product.quantity}
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => increaseQuantity(product.id)}
                    className="bg-green-500 text-white py-1 px-3 rounded hover:bg-green-600"
                  >
                    +
                  </button>
                  <button
                    onClick={() => decreaseQuantity(product.id)}
                    className="bg-yellow-500 text-white py-1 px-3 rounded hover:bg-yellow-600"
                  >
                    -
                  </button>
                  <button
                    onClick={() => removeFromCart(product.id)}
                    className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600"
                  >
                    Eliminar
                  </button>
                </div>
              </motion.li>
            ))}
          </motion.ul>
        ) : (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="mt-4 text-gray-500 text-center"
          >
            Carrito vacío
          </motion.p>
        )}
      </AnimatePresence>
      <div className="mt-6">
        <label className="block text-lg font-semibold text-gray-700">
          Método de Pago:
        </label>
        <select
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
          className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          <option value="" disabled>Selecciona un método de pago</option>
          <option value="efectivo">Efectivo</option>
          <option value="tarjeta">Tarjeta</option>
          <option value="transferencia">Transferencia</option>
        </select>
      </div>

      {cart.length > 0 && (
        <div className="mt-6 text-center">
          <h3 className="text-3xl font-bold text-gray-800">
            Total: ${calculateTotal()}
          </h3>
          <button
            onClick={handleCheckout}
            className="mt-4 bg-green-500 text-white py-3 px-6 rounded-lg hover:bg-green-600 text-lg font-semibold"
            disabled={loading}
          >
            {loading ? 'Procesando...' : 'Proceder con la Venta'}
          </button>
          {error && <p className="mt-2 text-red-600">{error}</p>}
        </div>
      )}

    </div>

  );
};

export default CompCarrito;
