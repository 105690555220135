// src/components/Caja.js
import React, { useState } from 'react';

const CompCaja = () => {
  const [sales, setSales] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [productName, setProductName] = useState('');
  const [productQuantity, setProductQuantity] = useState(1);
  const [productPrice, setProductPrice] = useState('');
  const [incomeDescription, setIncomeDescription] = useState('');
  const [incomeAmount, setIncomeAmount] = useState('');
  const [expenseDescription, setExpenseDescription] = useState('');
  const [expenseAmount, setExpenseAmount] = useState('');

  const handleAddSale = (e) => {
    e.preventDefault();
    const newSale = { productName, productQuantity, productPrice: parseFloat(productPrice) };
    setSales([...sales, newSale]);
    setProductName('');
    setProductQuantity(1);
    setProductPrice('');
  };

  const handleAddIncome = (e) => {
    e.preventDefault();
    const newIncome = { incomeDescription, incomeAmount: parseFloat(incomeAmount) };
    setIncomes([...incomes, newIncome]);
    setIncomeDescription('');
    setIncomeAmount('');
  };

  const handleAddExpense = (e) => {
    e.preventDefault();
    const newExpense = { expenseDescription, expenseAmount: parseFloat(expenseAmount) };
    setExpenses([...expenses, newExpense]);
    setExpenseDescription('');
    setExpenseAmount('');
  };

  const calculateTotalSales = () => {
    return sales.reduce((acc, sale) => acc + sale.productPrice * sale.productQuantity, 0);
  };

  const calculateTotalIncomes = () => {
    return incomes.reduce((acc, income) => acc + income.incomeAmount, 0);
  };

  const calculateTotalExpenses = () => {
    return expenses.reduce((acc, expense) => acc + expense.expenseAmount, 0);
  };

  const calculateBalance = () => {
    return calculateTotalSales() + calculateTotalIncomes() - calculateTotalExpenses();
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-4xl">
        <h1 className="text-2xl font-bold mb-6 text-center">Caja Diaria</h1>
        
        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Registrar Venta</h2>
          <form onSubmit={handleAddSale}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="productName">
                Nombre del Producto
              </label>
              <input
                type="text"
                id="productName"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="productQuantity">
                Cantidad
              </label>
              <input
                type="number"
                id="productQuantity"
                value={productQuantity}
                onChange={(e) => setProductQuantity(e.target.value)}
                required
                min="1"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="productPrice">
                Precio
              </label>
              <input
                type="number"
                id="productPrice"
                value={productPrice}
                onChange={(e) => setProductPrice(e.target.value)}
                required
                step="0.01"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Agregar Venta
              </button>
            </div>
          </form>
        </div>

        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Registrar Ingreso</h2>
          <form onSubmit={handleAddIncome}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="incomeDescription">
                Descripción del Ingreso
              </label>
              <input
                type="text"
                id="incomeDescription"
                value={incomeDescription}
                onChange={(e) => setIncomeDescription(e.target.value)}
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="incomeAmount">
                Monto
              </label>
              <input
                type="number"
                id="incomeAmount"
                value={incomeAmount}
                onChange={(e) => setIncomeAmount(e.target.value)}
                required
                step="0.01"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Agregar Ingreso
              </button>
            </div>
          </form>
        </div>

        <div className="mb-8">
          <h2 className="text-xl font-semibold mb-4">Registrar Egreso</h2>
          <form onSubmit={handleAddExpense}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="expenseDescription">
                Descripción del Egreso
              </label>
              <input
                type="text"
                id="expenseDescription"
                value={expenseDescription}
                onChange={(e) => setExpenseDescription(e.target.value)}
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="expenseAmount">
                Monto
              </label>
              <input
                type="number"
                id="expenseAmount"
                value={expenseAmount}
                onChange={(e) => setExpenseAmount(e.target.value)}
                required
                step="0.01"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Agregar Egreso
              </button>
            </div>
          </form>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">Resumen de Caja</h2>
          <p className="mb-2">Total Ventas: ${calculateTotalSales().toFixed(2)}</p>
          <p className="mb-2">Total Ingresos: ${calculateTotalIncomes().toFixed(2)}</p>
          <p className="mb-2">Total Egresos: ${calculateTotalExpenses().toFixed(2)}</p>
          <p className="font-bold text-lg">Balance: ${calculateBalance().toFixed(2)}</p>
        </div>
      </div>
    </div>
  );
};

export default CompCaja;
