import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


import CompShowInicio from './components/inicio';
import CompShowLogin from './components/login';
import CompVenta from './components/venta';
import CompInventario from './components/inventario';
import CompCaja from './components/caja';
import CompCompra from './components/comprarProducto';
import CompCarrito from './components/carrito';
import CompCrearDescuento from './components/createDescuento';
function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path='/' element={<CompShowInicio />} />
          <Route path='/login' element={<CompShowLogin />} />
          <Route path='/venta' element={<CompVenta />} />
          <Route path='/inventario' element={<CompInventario />} />
          <Route path='/caja' element={<CompCaja />} />
          <Route path='/comprar' element={<CompCompra />} />
          <Route path='/vender' element={<CompCarrito />} />
          <Route path='/crearDescuento' element={<CompCrearDescuento />} />
        </Routes>
      </div>
    </BrowserRouter >
  );
}

export default App;
